import { useLoaderData } from "@remix-run/react";
import { storyQuery } from "~/groq";
import { getClient } from "~/sanity";

import type { MetaFunction } from "@remix-run/cloudflare";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import Download from "~/components/home/Download";
import Features from "~/components/home/Features";
import HomeHero from "~/components/home/HomeHero";
import Stories from "~/components/home/Stories";

export const loader = async () => {
  const story = await getClient().fetch(storyQuery);

  return { story };
};

export const meta: MetaFunction = () => {
  return [
    { title: "Zest Cooking" },
    {
      property: "og:title",
      content: "Zest Cooking",
    },
    {
      name: "apple-itunes-app",
      content: "app-id=1595938390",
    },
  ];
};

export default function Index() {
  const { story } = useLoaderData<typeof loader>();

  return (
    <main
      className="overflow-hidden text-text-black"
      itemScope
      itemType="https://schema.org/SoftwareApplication"
    >
      <Header />
      <HomeHero />
      <Download />
      <Features />
      <Stories story={story} />
      <Footer />
    </main>
  );
}
